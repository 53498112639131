import cx from "classnames"
import PropTypes from "prop-types"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { NavLink, withRouter } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { MAIN_MENU } from "../../common"

const getMenuIndexes = () => {
	let sIndex = null
	let pIndex = null

	const menuListLength = MAIN_MENU.length

	for (let s = 0; s < menuListLength; s++) {
		const pageListLength = MAIN_MENU[s].pageList.length

		for (let p = 0; p < pageListLength; p++) {
			if (location.pathname === MAIN_MENU[s].pageList[p].sUrl) {
				sIndex = s
				pIndex = p
				break
			}

			const linkListLength = MAIN_MENU[s].pageList[p].linkList?.length

			if (linkListLength) {
				for (let l = 0; l < linkListLength; l++) {
					if (location.pathname === MAIN_MENU[s].pageList[p].linkList[l].pUrl) {
						sIndex = s
						pIndex = p
						break
					}
				}
			}
		}
	}

	return {
		sIndex,
		pIndex,
	}
}

const _PageComponent = ({
	item,
	openSection,
	sIndex,
	pIndex,
	isOpen,
	...props
}) => {
	const [height, setHeight] = useState(0)

	const ref = useRef(null)

	const handleClick = url => {
		!url && openSection()
	}

	useEffect(() => {
		if (ref.current) {
			setHeight(ref.current.clientHeight)
		}
	}, [ref])

	return (
		<li className={cx({ _active: isOpen })}>
			<NavLink
				to={item.sUrl || "#"}
				className={cx(
					"waves-effect d-flex align-items-center justify-content-between",
					{ "has-arrow": !item.sUrl }
				)}
				aria-expanded="false"
				activeClassName={item.sUrl === location.pathname ? "_active" : null}
				onClick={() => handleClick(item.sUrl)}
			>
				<span className="d-flex align-items-center">
					<i className={cx("bx", item.icon)} />
					<span>{props.t(item.pName)}</span>
				</span>

				{/*<span className="badge badge-pill badge-info float-right">*/}
				{/*	04*/}
				{/*</span>*/}

				{!item.sUrl && <i className="bx bxs-chevron-down arrow" />}
			</NavLink>

			{item.linkList && (
				<div
					className="sub-menu-wrap"
					style={{ height: `${isOpen ? height : 0}px` }}
				>
					<ul className="sub-menu" ref={ref}>
						{item.linkList.map(item => (
							<li key={item.name}>
								<NavLink to={item.pUrl} activeClassName="_active">
									{props.t(item.name)}
								</NavLink>
							</li>
						))}
					</ul>
				</div>
			)}
		</li>
	)
}
const PageComponent = withTranslation()(_PageComponent)

const SidebarContent = ({ location, ...props }) => {
	const [sectionIndex, setSectionIndex] = useState(0)
	const [pageIndex, setPageIndex] = useState(0)

	const initMenu = () => {
		const { sIndex, pIndex } = getMenuIndexes()

		setSectionIndex(sIndex)
		setPageIndex(pIndex)
	}

	const openSection = (sIndex, pIndex) => {
		if (sIndex === sectionIndex && pIndex === pageIndex) {
			setSectionIndex(null)
			setPageIndex(null)
		} else {
			setSectionIndex(sIndex)
			setPageIndex(pIndex)
		}
	}

	useEffect(() => {
		initMenu()

		window.scrollTo(0, 0)
	}, [location.pathname])

	useEffect(() => {

	}, [])

	return (
		<SimpleBar style={{ maxHeight: "100%" }}>
			<div id="sidebar-menu">
				<ul className="metismenu list-unstyled m-0" id="side-menu">
					{MAIN_MENU.map((item, sIndex) => (
						<Fragment key={item.catName}>
							<li className="menu-title">{props.t(item.catName)}</li>

							{item.pageList.map((item, pIndex) => (
								<PageComponent
									key={item.pName}
									sIndex={sIndex}
									pIndex={pIndex}
									item={item}
									isOpen={sectionIndex === sIndex && pageIndex === pIndex}
									openSection={() => openSection(sIndex, pIndex)}
								/>
							))}
						</Fragment>
					))}
				</ul>
			</div>
		</SimpleBar>
	)
}

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
