import React from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import SidebarContent from "./SidebarContent"

const Sidebar = () => {
	return (
		<div className="vertical-menu">
			<div data-simplebar className="h-100">
				<SidebarContent />
			</div>
		</div>
	)
}

export default withRouter(withTranslation()(Sidebar))
