import PropTypes from "prop-types"
import React, { useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Col,
	Container,
	Media,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
} from "reactstrap"
import modalimage2 from "../../assets/images/product/img-4.png"

import modalimage1 from "../../assets/images/product/img-7.png"

//Import Index
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import Earning from "../Dashboard-saas/earning"
import SalesAnalytics from "../Dashboard-saas/sales-analytics"
import MonthlyEarning from "./MonthlyEarning"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

// Pages Components

const Dashboard = props => {
	const [modal, setModal] = useState(false)

	const reports = [
		{
			title: "Оборот за неделю",
			iconClass: "bx-ruble",
			description: 1235,
		},
		{
			title: "Оборот за месяц",
			iconClass: "bx-ruble",
			description: 35723,
		},
		{
			title: "Оборот за год",
			iconClass: "bx-ruble",
			description: 357231235,
		},
	]
	const email = [
		{ title: "Неделя", linkto: "#", isActive: false },
		{ title: "Месяц", linkto: "#", isActive: false },
		{ title: "Год", linkto: "#", isActive: true },
	]

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs
						title="Панель управления"
						breadcrumbItem="Панель управления"
					/>

					<Row>
						<Col xl="4">
							<MonthlyEarning />

							<SalesAnalytics />
						</Col>
						<Col xl="8">
							<Row>
								{reports.map((report, index) => (
									<Col md="4" key={index + ""}>
										<Card className="mini-stats-wid">
											<CardBody>
												<Media>
													<Media body>
														<p className="text-muted font-weight-medium">
															{report.title}
														</p>
														<h4 className="mb-0">
															{report.description.toLocaleString("ru-RU", {
																style: "currency",
																currency: "RUB",
																minimumFractionDigits: 0,
															})}
														</h4>
													</Media>

													<div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
														<span className="avatar-title">
															<i
																className={`bx ${report.iconClass} font-size-24`}
															/>
														</span>
													</div>
												</Media>
											</CardBody>
										</Card>
									</Col>
								))}
							</Row>

							<Earning />

							<Card>
								<CardBody>
									<CardTitle className="mb-4 float-sm-left">
										Платежи по роялти и маркетингу
									</CardTitle>

									<div className="float-sm-right">
										<ul className="nav nav-pills">
											{email.map((mail, key) => (
												<li className="nav-item" key={"_li_" + key}>
													<Link
														className={
															mail.isActive ? "nav-link active" : "nav-link"
														}
														to={mail.linkto}
													>
														{mail.title}
													</Link>
												</li>
											))}
										</ul>
									</div>

									<div className="clearfix"></div>

									<StackedColumnChart />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>

			<Modal
				isOpen={modal}
				role="dialog"
				autoFocus={true}
				centered={true}
				className="exampleModal"
				tabindex="-1"
				toggle={() => {
					setModal(!modal)
				}}
			>
				<div className="modal-content">
					<ModalHeader
						toggle={() => {
							setModal(!modal)
						}}
					>
						Order Details
					</ModalHeader>
					<ModalBody>
						<p className="mb-2">
							Product id: <span className="text-primary">#SK2540</span>
						</p>
						<p className="mb-4">
							Billing Name: <span className="text-primary">Neal Matthews</span>
						</p>

						<div className="table-responsive">
							<Table className="table table-centered table-nowrap">
								<thead>
									<tr>
										<th scope="col">Product</th>
										<th scope="col">Product Name</th>
										<th scope="col">Price</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">
											<div>
												<img src={modalimage1} alt="" className="avatar-sm" />
											</div>
										</th>
										<td>
											<div>
												<h5 className="text-truncate font-size-14">
													Wireless Headphone (Black)
												</h5>
												<p className="text-muted mb-0">$ 225 x 1</p>
											</div>
										</td>
										<td>$ 255</td>
									</tr>
									<tr>
										<th scope="row">
											<div>
												<img src={modalimage2} alt="" className="avatar-sm" />
											</div>
										</th>
										<td>
											<div>
												<h5 className="text-truncate font-size-14">
													Hoodie (Blue)
												</h5>
												<p className="text-muted mb-0">$ 145 x 1</p>
											</div>
										</td>
										<td>$ 145</td>
									</tr>
									<tr>
										<td colSpan="2">
											<h6 className="m-0 text-right">Sub Total:</h6>
										</td>
										<td>$ 400</td>
									</tr>
									<tr>
										<td colSpan="2">
											<h6 className="m-0 text-right">Shipping:</h6>
										</td>
										<td>Free</td>
									</tr>
									<tr>
										<td colSpan="2">
											<h6 className="m-0 text-right">Total:</h6>
										</td>
										<td>$ 400</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							type="button"
							color="secondary"
							onClick={() => {
								setModal(!modal)
							}}
						>
							Close
						</Button>
					</ModalFooter>
				</div>
			</Modal>
		</>
	)
}

Dashboard.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(Dashboard)
