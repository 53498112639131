import React, { Component } from "react"

import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"

class Earning extends Component {
	constructor(props) {
		super(props)
		this.state = {
			series: [
				{
					name: "Оборот",
					data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76],
				},
			],
			options: {
				chart: {
					toolbar: "false",
					dropShadow: {
						enabled: !0,
						color: "#000000",
						top: 18,
						left: 7,
						blur: 8,
						opacity: 0.2,
					},
				},
				dataLabels: {
					enabled: !1,
				},
				colors: ["#71be73"],
				stroke: {
					curve: "smooth",
					width: 3,
				},
			},
		}
	}

	render() {
		return (
			<Card>
				<CardBody>
					<div className="clearfix">
						<div className="float-right">
							<div className="input-group input-group-sm">
								<select className="custom-select custom-select-sm">
									<option defaultValue value="1">
										Янв
									</option>
									<option value="2">Фев</option>
									<option value="3">Мар</option>
									<option value="4">Апр</option>
									<option value="5">Май</option>
									<option value="6">Июн</option>
									<option value="7">Июл</option>
									<option value="8">Авг</option>
									<option value="9">Сен</option>
									<option value="10">Окт</option>
									<option value="11">Ноя</option>
									<option value="12">Дек</option>
								</select>
								<div className="input-group-append">
									<label className="input-group-text">Месяц</label>
								</div>
							</div>
						</div>
						<h4 className="card-title mb-4">Оборот сети</h4>
					</div>

					<div id="line-chart" className="apex-charts" dir="ltr">
						<ReactApexChart
							series={this.state.series}
							options={this.state.options}
							type="line"
							height={320}
						/>
					</div>
				</CardBody>
			</Card>
		)
	}
}

export default Earning
