export const MAIN_MENU = [
	{
		catName: "Основное",
		pageList: [
			{
				pName: "Панель управления",
				sUrl: "/",
				icon: "bx-home-circle",
			},
		],
	},
	{
		catName: "Заведения",
		pageList: [
			{
				pName: "Заведения",
				sUrl: "/institution/list",
				icon: "bxs-bank",
			},
			{
				pName: "Магазины",
				sUrl: "/in-work",
				icon: "bxs-shopping-bags",
			},
			{
				pName: "Договоры по заведениям",
				sUrl: "/in-work",
				icon: "bx-file",
			},
			{
				pName: "Договоры по магазинам",
				sUrl: "/in-work",
				icon: "bx-file",
			},
			{
				pName: "Платежи",
				sUrl: "/in-work",
				icon: "bx-money",
			},
			{
				pName: "Бронирования",
				sUrl: "/in-work",
				icon: "bx-calendar",
			},
			{
				pName: "Контракты",
				sUrl: "/in-work",
				icon: "bx-file",
			},
			{
				pName: "Кэшбеки",
				sUrl: "/in-work",
				icon: "bx-money",
			},
		],
	},
	{
		catName: "Люди",
		pageList: [
			{
				pName: "Пользователи",
				sUrl: "/in-work",
				icon: "bxs-user-account",
			},
			{
				pName: "Партнеры",
				sUrl: "/in-work",
				icon: "bxs-user-detail",
			},
			{
				pName: "Гости",
				sUrl: "/in-work",
				icon: "bxs-user-detail",
			},
		],
	},
	{
		catName: "Новости и события",
		pageList: [
			{
				pName: "Новости",
				sUrl: "/in-work",
				icon: "bx-news",
			},
			{
				pName: "Мероприятия",
				sUrl: "/in-work",
				icon: "bx-calendar-event",
			},
			{
				pName: "Календарь событий",
				sUrl: "/in-work",
				icon: "bx-calendar-event",
			},
			{
				pName: "Материалы",
				icon: "bx-box",
				isOpen: false,
				linkList: [
					{
						name: "Обучающие материалы",
						pUrl: "/in-work",
					},
					{
						name: "База знаний",
						pUrl: "/in-work",
					},
					{
						name: "Рекламные материалы",
						pUrl: "/in-work",
					},
				],
			},
		],
	},
	{
		catName: "Модерация",
		pageList: [
			{
				pName: "Модерация пушей",
				sUrl: "/in-work",
				icon: "bx-bell",
			},
			{
				pName: "Дубликат транзакций",
				sUrl: "/in-work",
				icon: "bx-error-alt",
			},
			{
				pName: "Изменение версий",
				icon: "bx-check-circle",
				isOpen: false,
				linkList: [
					{
						name: "Изменение заведений",
						pUrl: "/in-work",
					},
					{
						name: "Изменение новостей",
						pUrl: "/in-work",
					},
					{
						name: "Изменение магазинов",
						pUrl: "/in-work",
					},
				],
			},
		],
	},
	{
		catName: "Прочее",
		pageList: [
			{
				pName: "Процессы",
				sUrl: "/in-work",
				icon: "bx-cog",
			},
			{
				pName: "Права доступа",
				icon: "bx-lock-open-alt",
				isOpen: false,
				linkList: [
					{
						name: "Доступ к партнерам",
						pUrl: "/in-work",
					},
					{
						name: "Доступ к договорам",
						pUrl: "/in-work",
					},
					{
						name: "Доступ к заведениям",
						pUrl: "/in-work",
					},
					{
						name: "Доступ к магазинам",
						pUrl: "/in-work",
					},
					{
						name: "Доступ к контрактам",
						pUrl: "/in-work",
					},
				],
			},

			{
				pName: "Тех. поддержка",
				icon: "bx-support",
				isOpen: false,
				linkList: [
					{
						name: "Поддержка",
						pUrl: "/in-work",
					},
					{
						name: "Закрытые обращения",
						pUrl: "/in-work",
					},
				],
			},
			{
				pName: "Справочник",
				icon: "bx-book-alt",
				isOpen: false,
				linkList: [
					{
						name: "Исключение номеров",
						pUrl: "/in-work",
					},
					{
						name: "Станции метро",
						pUrl: "/in-work",
					},
					{
						name: "Меню",
						pUrl: "/in-work",
					},
					{
						name: "Описание контрактов",
						pUrl: "/in-work",
					},
				],
			},
		],
	},
]
