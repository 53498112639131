import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap"
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { appLogout } from "../../../redux/actions/app"

const menuList = [
	{
		text: "Profile",
		icon: "user",
		link: "#",
	},
	{
		text: "My Wallet",
		icon: "wallet",
		link: "#",
	},
	{
		text: "Settings",
		link: "#",
	},
]

const ProfileMenu = ({ history, ...props }) => {
	const dispatch = useDispatch()

	const [menu, setMenu] = useState(false)

	const handleLogout = () => {
		dispatch(appLogout())
		history.replace("/login")
	}

	// useEffect(() => {
	//
	// },[menu])


	return (
		<Dropdown
			isOpen={menu}
			toggle={() => setMenu(!menu)}
			className="d-inline-block"
		>
			<DropdownToggle
				className="btn header-item waves-effect"
				id="page-header-user-dropdown"
				tag="button"
			>
				<img
					className="rounded-circle header-profile-user"
					src={user1}
					alt="Header Avatar"
				/>

				<span className="d-none d-xl-inline-block ml-2 mr-1">Admin</span>

				<i className="bx bxs-chevron-down d-none d-xl-inline-block" />
			</DropdownToggle>

			<DropdownMenu right>
				{menuList.map(i => (
					<DropdownItem
						key={i.text}
						tag="a"
						href={i.link}
						className="d-flex align-items-center"
					>
						{i.icon && <i className={`bx bx-${i.icon} font-size-16 mr-1`} />}
						{i.text}
					</DropdownItem>
				))}

				<div className="dropdown-divider" />

				<button
					className="dropdown-item d-flex align-items-center"
					onClick={() => handleLogout()}
				>
					<i className="bx bx-power-off font-size-16 mr-1 text-danger" />
					<span>{props.t("Logout")}</span>
				</button>
			</DropdownMenu>
		</Dropdown>
	)
}

ProfileMenu.propTypes = {
	t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileMenu))
