export const checkUserAuth = async () => {
	let returnData = false
	const authToken = localStorage.getItem("authToken")

	if (authToken) {
		// const res = await apiGet({
		// 	url: "/profile",
		// 	headerList: {
		// 		Authorization: `Bearer ${authToken}`,
		// 	},
		// })

		// if (res) {
		returnData = {
			// userData: res.data,
			authToken,
		}
		// }
	}

	return returnData
}
