import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, Col, Row } from "reactstrap"

const SalesAnalytics = () => {
	const [series, setSeries] = useState([56, 38, 26])
	const [options, setOptions] = useState({
		labels: ["Открыто", "Закрыто", "На ремонте"],
		colors: ["#34c38f", "#f46a6a", "#556ee6"],
		legend: { show: !1 },
		plotOptions: {
			pie: {
				donut: {
					size: "70%",
				},
			},
		},
	})

	return (
		<Card>
			<CardBody>
				<h4 className="card-title mb-4">Состояние заведений</h4>

				<div>
					<div id="donut-chart" className="apex-charts">
						<ReactApexChart
							options={options}
							series={series}
							type="donut"
							height={240}
						/>
					</div>
				</div>

				<div className="text-center text-muted">
					<Row>
						<Col xs="4">
							<div className="mt-4">
								<p className="mb-2 text-truncate">
									<i className="mdi mdi-circle text-primary mr-1" /> Открыто
								</p>
								<h5>56</h5>
							</div>
						</Col>
						<Col xs="4">
							<div className="mt-4">
								<p className="mb-2 text-truncate">
									<i className="mdi mdi-circle text-success mr-1" /> Закрыто
								</p>
								<h5>38</h5>
							</div>
						</Col>
						<Col xs="4">
							<div className="mt-4">
								<p className="mb-2 text-truncate">
									<i className="mdi mdi-circle text-danger mr-1" /> На ремонте
								</p>
								<h5>26</h5>
							</div>
						</Col>
					</Row>
				</div>
			</CardBody>
		</Card>
	)
}

export default SalesAnalytics
