import React from "react"
import ForgetPassword from "../pages/Authentication/ForgetPassword"
import Login from "../pages/Authentication/Login"
import Register from "../pages/Authentication/Register"
import Dashboard from "../pages/Dashboard"
import InstitutionList from "../pages/Institution/List"
import InWork from "../pages/InWork"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

const userRoutes = [
	{
		path: "/pages-404",
		component: Pages404,
	},
	{
		path: "/pages-500",
		component: Pages500,
	},
	{
		path: "/in-work",
		component: InWork,
	},
	{ path: "/", component: Dashboard },
	{ path: "/institution/list", component: InstitutionList },
]

const authRoutes = [
	{ path: "/", component: Login },
	{
		path: "/forgot-password",
		component: ForgetPassword,
	},
	{ path: "/register", component: Register },
]

export { userRoutes, authRoutes }
