import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch } from "react-router-dom"
import "./assets/scss/index.scss"
import { LoaderComponent } from "./components/Common"
import VerticalLayout from "./components/VerticalLayout/"
import { checkUserAuth } from "./helpers/auth"
import { appLogin } from "./redux/actions/app"
import { authRoutes, userRoutes } from "./routes/allRoutes"

const App = () => {
	const state = useSelector(s => s)
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(true)

	const renderRoute = i => (
		<Route exact key={i.path} path={i.path} component={i.component} />
	)

	useEffect(() => {
		checkUserAuth()
			.then(res => {
				if (res) {
					dispatch(appLogin("1"))
				}
			})
			.finally(() => setLoading(!loading))
	}, [])

	if (loading) {
		return <LoaderComponent />
	}

	return (
		<>
			{state.app.authToken ? (
				<VerticalLayout>{userRoutes.map(renderRoute)}</VerticalLayout>
			) : (
				authRoutes.map(renderRoute)
			)}
		</>
	)
}

export default App
