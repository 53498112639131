import React from "react"
import { Link } from "react-router-dom"

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"

import ApexRadial from "./ApexRadial"

const num = 34252

const MonthlyEarning = () => {
	return (
		<Card>
			<CardBody>
				<CardTitle className="mb-4">Подключенных заведений</CardTitle>

				<Row>
					<Col sm="6">
						<p className="text-muted">В этом месяце</p>
						<h3>
							{num.toLocaleString("ru-RU", {
								style: "currency",
								currency: "RUB",
								minimumFractionDigits: 0,
							})}
						</h3>
						<p className="text-muted">
							<span className="text-success mr-2">
								{" "}
								+12% <i className="mdi mdi-arrow-up" />{" "}
							</span>{" "}
							По сравнению с прошлым месяцем
						</p>
						<div className="mt-4">
							<Link
								to=""
								className="btn btn-success waves-effect btn-sm d-inline-flex align-items-center"
							>
								Подробнее <i className="bx bx-right-arrow-alt ml-1" />
							</Link>
						</div>
					</Col>
					<Col sm="6">
						<div className="mt-4 mt-sm-0">
							<ApexRadial />
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	)
}

export default MonthlyEarning
