import React from "react"
import { Container } from "reactstrap"

const Footer = () => {
	return (
		<footer className="footer">
			<Container fluid={true}>{new Date().getFullYear()}. Мята Admin</Container>
		</footer>
	)
}

export default Footer
