import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter, Switch } from "react-router-dom"
import App from "./App"
import { store } from "./redux/store"
import * as serviceWorker from "./serviceWorker"

const MyApp = (
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<Switch>
					<App />
				</Switch>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
)

ReactDOM.render(MyApp, document.getElementById("root"))
serviceWorker.unregister()
