import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import logoLightSvg from "../../assets/images/logo-light.svg"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

const Header = () => {
	const [search, setSearch] = useState(false)



	const toggleFullscreen = () => {
		if (
			!document.fullscreenElement &&
			/* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen()
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen()
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				)
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen()
			}
		}
	}

	const handleToggleMenu = () => {
		document.querySelector(".vertical-menu").classList.toggle("_visible")
	}


	useEffect(() => {
		document.querySelector(".vertical-menu").classList.remove("_visible")
	},[window.location.pathname])


	return (
		<header id="page-topbar">
			<div className="navbar-header">
				<div className="d-flex h-100">
					<div className="navbar-brand-box d-flex align-items-center">
						{/*<Link to="/" className="logo logo-dark">*/}
						{/*	<span className="logo-sm">*/}
						{/*		<img src={logo} alt="" height="22" />*/}
						{/*	</span>*/}
						{/*	<span className="logo-lg">*/}
						{/*		<img src={logo} alt="" height="17" />*/}
						{/*	</span>*/}
						{/*</Link>*/}

						<Link
							to="/"
							className="logo logo-light d-flex align-items-center font-size-16">
							<img src={logoLightSvg} alt="" className="image-contain" />
						</Link>
					</div>

					<button
						type="button"
						onClick={handleToggleMenu}
						className="d-inline-flex d-lg-none align-items-center justify-content-center btn btn-sm px-2 font-size-24 header-item waves-effect"
						id="vertical-menu-btn">
						<i className="bx bx-menu" />
					</button>
				</div>

				<div className="d-flex">
					<div className="dropdown d-inline-block d-lg-none ml-2">
						<button
							onClick={() => {
								setSearch(!search)
							}}
							type="button"
							className="btn header-item noti-icon waves-effect"
							id="page-header-search-dropdown">
							<i className="bx bx-search" />
						</button>

						<div
							className={
								search
									? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
									: "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
							}
							aria-labelledby="page-header-search-dropdown">
							<form className="p-3">
								<div className="form-group m-0">
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											placeholder="Search ..."
											aria-label="Recipient's username"
										/>
										<div className="input-group-append">
											<button className="btn btn-primary" type="submit">
												<i className="bx bx-search" />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					{/*<LanguageDropdown />*/}

					<div className="dropdown d-none d-lg-inline-block ml-1">
						<button
							type="button"
							onClick={() => {
								toggleFullscreen()
							}}
							className="btn header-item noti-icon waves-effect"
							data-toggle="fullscreen">
							<i className="bx bx-fullscreen" />
						</button>
					</div>

					<NotificationDropdown />

					<ProfileMenu />
				</div>
			</div>
		</header>
	)
}

Header.propTypes = {
	changeSidebarType: PropTypes.func,
	leftMenu: PropTypes.any,
	leftSideBarType: PropTypes.any,
	showRightSidebar: PropTypes.any,
	showRightSidebarAction: PropTypes.func,
	t: PropTypes.any,
	toggleLeftmenu: PropTypes.func,
}

export default withTranslation()(Header)
