import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import {
	Breadcrumb as BreadcrumbWrap,
	BreadcrumbItem,
	Col,
	Row,
} from "reactstrap"

const Breadcrumb = ({ breadcrumbItem }) => {
	return (
		<Row>
			<Col xs="12">
				<div className="page-title-box d-flex align-items-center justify-content-between">
					<h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>

					<div className="d-none d-md-block page-title-right">
						<BreadcrumbWrap listClassName="mb-0">
							<BreadcrumbItem>
								<Link to="/">Главная</Link>
							</BreadcrumbItem>
							<BreadcrumbItem active>{breadcrumbItem}</BreadcrumbItem>
						</BreadcrumbWrap>
					</div>
				</div>
			</Col>
		</Row>
	)
}
Breadcrumb.propTypes = {
	breadcrumbItem: PropTypes.string,
}

export default Breadcrumb
