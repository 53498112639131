import React from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import logoImg from "../../assets/images/logo.svg"
import profileImg from "../../assets/images/profile-img.png"
import FormInput from "../../components/Form/Input"

const Register = () => {
	const { register, handleSubmit } = useForm()
	const onSubmit = data => {
		console.log(data)
	}

	return (
		<div className="account-pages my-5 pt-sm-5">
			<Container>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden">
							<div className="bg-soft-primary">
								<Row>
									<Col className="col-7">
										<div className="text-primary p-4">
											<h5 className="text-primary">Free Register</h5>
											<p>Get your free WOCOM Admin account now.</p>
										</div>
									</Col>
									<Col className="col-5 align-self-end">
										<img src={profileImg} alt="" className="img-fluid" />
									</Col>
								</Row>
							</div>

							<CardBody className="pt-0">
								<Link
									to="/"
									className="d-inline-block avatar-md profile-user-wid mb-4"
								>
									<span className="avatar-title rounded-circle bg-light">
										<img
											src={logoImg}
											alt=""
											className="rounded-circle"
											height="34"
										/>
									</span>
								</Link>

								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="mb-3">
										<FormInput
											register={register}
											label="Email"
											name="email"
											placeholder="Ввведите email"
											type="email"
										/>
									</div>

									<div className="mb-3">
										<FormInput
											register={register}
											label="Пароль"
											name="password"
											placeholder="Ввведите пароль"
											type="password"
										/>
									</div>

									<div className="mb-3">
										<FormInput
											register={register}
											label="Повторите пароль"
											name="repeat-password"
											placeholder="Ввведите пароль"
											type="password"
										/>
									</div>

									<div className="mt-4">
										<button
											className="btn btn-primary btn-block waves-effect waves-light"
											type="submit"
										>
											Регистрация
										</button>
									</div>

									<div className="mt-4 text-center">
										<p className="mb-0">
											By registering you agree to the WOCOM Admin{" "}
											<Link to="#" className="text-primary">
												Terms of Use
											</Link>
										</p>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className="mt-5 text-center">
							<p>
								Already have an account ?{" "}
								<Link to="/login" className="font-weight-medium text-primary">
									{" "}
									Login
								</Link>{" "}
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Register
