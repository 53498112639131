import React, { useState } from "react"
import { useForm } from "react-hook-form"
import SelectCustom from "react-select"
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumbs"
import FormInput from "../../../components/Form/Input"

const InstitutionList = () => {
	const [modal, setModal] = useState(false)
	const [selectedGroup, setSelectedGroup] = useState(null)

	const { register, handleSubmit } = useForm()

	const optionGroup = [
		{ label: "Да", value: 0 },
		{ label: "Нет", value: 1 },
	]

	const onSubmit = values => {
		console.log(values)
	}

	const handleSelectGroup = selectedGroup => {
		setSelectedGroup(selectedGroup)
	}

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Заведения" breadcrumbItem="Заведения" />

					<button
						type="submit"
						className="btn btn-success my-4"
						onClick={() => setModal(!modal)}
					>
						Настроить поля
					</button>

					<form onSubmit={handleSubmit(onSubmit)} className="mb-5">
						<Row className="align-items-end">
							<Col>
								<FormInput
									register={register}
									label="Поиск"
									name="query"
									placeholder="Название заведения"
								/>
							</Col>

							<Col sm="auto">
								<button type="submit" className="btn btn-success mt-3">
									Поиск
								</button>
							</Col>
						</Row>
					</form>

					<Card>
						<CardBody>
							<div className="table-rep-plugin">
								<div className="table-responsive">
									<Table
										id="tech-companies-1"
										className="table table-striped table-bordered mb-0"
									>
										<thead>
											<tr>
												<th>#</th>
												<th>ФИО партнера</th>
												<th>Адрес</th>
												<th>Яндекс. Приоритет</th>
												<th>Постер</th>
												<th>Сбербанк Эквайринг</th>
												<th>Кальяны</th>
												<th>WTO</th>
												<th>Satyr</th>
												<th>Уголь</th>
												<th>Мундштуки</th>
												<th>AbInBev</th>
												<th>Coca - Cola</th>
												<th>Complex Bar & Monin</th>
												<th>Red Bull</th>
												<th>Tea Note</th>
												<th>Agrobar</th>
												<th>Solt</th>
												<th>Julius Meinl</th>
												<th>Santo Stefano</th>
												<th>Element</th>
												<th>Virginia Original</th>
												<th>Sebero</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td></td>
												<td>
													<FormInput inputStyles={{ width: 140 }} />
												</td>
												<td>
													<FormInput inputStyles={{ width: 180 }} />
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
												<td>
													<SelectCustom
														placeholder="Выберите"
														className="select"
														classNamePrefix="sr"
														value={selectedGroup}
														onChange={handleSelectGroup}
														options={optionGroup}
													/>
												</td>
											</tr>
											{Array.from({ length: 20 }).map(_ => {
												return (
													<tr>
														<td>1</td>
														<td>Леонтьев Максим</td>
														<td>Россия, Березники, ул. Советский проспект</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
														<td>Нет</td>
													</tr>
												)
											})}
										</tbody>
									</Table>
								</div>
							</div>
						</CardBody>
					</Card>
				</Container>
			</div>

			<Modal
				isOpen={modal}
				role="dialog"
				autoFocus={true}
				centered={true}
				className="exampleModal"
				tabindex="-1"
				size="lg"
				toggle={() => setModal(!modal)}
			>
				<ModalHeader
					toggle={() => {
						setModal(!modal)
					}}
				>
					Настройка колонок
				</ModalHeader>

				<ModalBody>
					<div className="mb-5">
						<div className="h2 mb-3">Заведение</div>

						<div className="mb-2">
							<div className="h4">Общая информация</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
						<div className="mb-2">
							<div className="h4">Услуги</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
						<div className="mb-2">
							<div className="h4">IT</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
						<div className="mb-2">
							<div className="h4">Контракты</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
					</div>

					<div>
						<div className="h2 mb-3">Договор</div>

						<div className="mb-2">
							<div className="h4">Организация</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
						<div className="mb-2">
							<div className="h4">Договоры</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
						<div className="mb-2">
							<div className="h4">Мебель</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
						<div className="mb-2">
							<div className="h4">Другое</div>
							<Row>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										ФИО парнтера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Телефон партнера
									</label>
								</Col>
								<Col>
									<label className="form-check mb-3">
										<input className="form-check-input" type="checkbox" />
										Название
									</label>
								</Col>
							</Row>
						</div>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button color="success" onClick={() => setModal(!modal)}>
						Сохранить
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

InstitutionList.propTypes = {}

export default InstitutionList
