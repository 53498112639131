import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import FormInput from "../../components/Form/Input"
import { appLogin } from "../../redux/actions/app"

const Login = ({ history }) => {
	const dispatch = useDispatch()

	const { register, handleSubmit } = useForm()
	const onSubmit = data => {
		dispatch(appLogin("1"))

		history.replace("/")
	}

	return (
		<div className="account-pages my-5 pt-sm-5">
			<Container>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card>
							<CardBody>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="mb-3">
										<FormInput
											register={register}
											label="Email"
											name="email"
											placeholder="Ввведите email"
											type="email"
										/>
									</div>

									<div className="mb-3">
										<FormInput
											register={register}
											label="Пароль"
											name="password"
											placeholder="Ввведите пароль"
											type="password"
										/>
									</div>

									<div className="mt-4">
										<button className="btn btn-primary btn-block" type="submit">
											Войти
										</button>
									</div>
								</form>

								<div className="mt-4 text-center">
									<Link to="/forgot-password" className="text-muted">
										<i className="bx bxs-lock mr-1" />
										Forgot your password?
									</Link>
								</div>
							</CardBody>
						</Card>

						<p className="mt-5 text-center">
							Нет аккаунта?{" "}
							<Link to="register" className="font-weight-medium text-primary">
								Signup now
							</Link>
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Login
