import React from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import logo from "../../assets/images/logo.svg"
import profile from "../../assets/images/profile-img.png"
import FormInput from "../../components/Form/Input"

const ForgetPasswordPage = () => {
	const { register, handleSubmit } = useForm()
	const onSubmit = data => {
		console.log(data)
	}

	return (
		<div className="account-pages my-5 pt-sm-5">
			<Container>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden">
							<div className="bg-soft-primary">
								<Row>
									<Col className="col-7">
										<div className="text-primary p-4">
											<h5 className="text-primary">Welcome Back !</h5>
											<p>Sign in to continue to WOCOM Admin.</p>
										</div>
									</Col>
									<Col className="col-5 align-self-end">
										<img src={profile} alt="" className="img-fluid" />
									</Col>
								</Row>
							</div>
							<CardBody className="pt-0">
								<div>
									<Link to="/">
										<div className="avatar-md profile-user-wid mb-4">
											<span className="avatar-title rounded-circle bg-light">
												<img
													src={logo}
													alt=""
													className="rounded-circle"
													height="34"
												/>
											</span>
										</div>
									</Link>
								</div>

								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="mb-3">
										<FormInput
											register={register}
											label="Email"
											name="email"
											placeholder="Ввведите email"
											type="email"
										/>
									</div>

									<Row className="form-group">
										<Col className="text-right">
											<button
												className="btn btn-primary w-md waves-effect waves-light"
												type="submit"
											>
												Reset
											</button>
										</Col>
									</Row>
								</form>
							</CardBody>
						</Card>

						<div className="mt-5 text-center">
							<p>
								Go back to{" "}
								<Link to="login" className="font-weight-medium text-primary">
									Login
								</Link>{" "}
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default ForgetPasswordPage
