import React from "react"

const FormInput = ({
	register,
	name,
	label,
	placeholder,
	inputStyles = {},
	type,
	error = null,
	...props
}) => {
	return (
		<>
			<label className="d-flex flex-column mb-0" style={inputStyles}>
				{label && <span className="label mb-1">{label}</span>}

				<input
					ref={register}
					name={name}
					placeholder={placeholder}
					type={type}
					className="form-control"
					{...props}
				/>
			</label>

			{error && <div className="mt-1 invalid-feedback">{error}</div>}
		</>
	)
}

export default FormInput
