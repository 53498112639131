import React from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import error from "../../assets/images/error-img.png"

const InWork = () => {
	return (
		<div className="page-content">
			<div className="account-pages">
				<Container fluid>
					<Row className="justify-content-center">
						<Col lg="12">
							<div className="text-center mb-5 mt-5">
								<div className="h1 font-weight-medium">В разработке</div>
								<div className="text-uppercase mt-4">
									Мы ещё пока трудимся над созданием этого раздела
								</div>
								<div className="mt-5 text-center">
									<Link
										className="btn btn-primary waves-effect waves-light"
										to="/"
									>
										На главную
									</Link>
								</div>
							</div>
						</Col>

						<Col md="8" xl="6">
							<div>
								<img src={error} alt="" className="img-fluid" />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	)
}

InWork.propTypes = {}

export default InWork
