import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import Footer from "./Footer"
import Header from "./Header"
import Sidebar from "./Sidebar"

const Layout = ({ children }) => {
	const toggleMenuCallback = () => {}

	return (
		<div id="layout-wrapper">
			<Header toggleMenuCallback={() => toggleMenuCallback()} />

			<Sidebar />

			<div className="main-content">{children}</div>

			<Footer />
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.any,
}

export default withRouter(Layout)
